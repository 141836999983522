import React from 'react';
import Layout from '../../components/layout/Layout';

export default () => (
  <Layout>
    <div className='bg-transparent'>
      <div className='max-w-7xl mx-auto px-4 mt-4'>
        <div className='py-6 text-center'>
          <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
            Thank you!
          </h2>
          <h3 className='mt-2 text-4xl font-extrabold tracking-tight text-white'>
            We will be in contact shortly
          </h3>
        </div>
      </div>
    </div>
  </Layout>
);
